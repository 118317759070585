import {Status} from "@/views/commons/search/utils/enums";
import {Errors} from "@/enums/response_errors";

const state = () => ({
  mixinResults: {
    body: null,
    Status,
    Errors,
    status: Status.NONE,
    valid: true,
    valid_status: Status.NONE,
    count_result: null,
    error: null,
    verbose_body: null,
    stored_results: [],
    offset_next_page: null
  },
  timeConstraints: null,
});

const getters = {
  getMixinResults: (state) => state.mixinResults,
  getTimeConstraints: (state) => state.timeConstraints,
};

const mutations = {
  setResultMixin(state, data) {
    state.mixinResults.body = data.body;
    state.mixinResults.status = data.status;
    state.mixinResults.offset_next_page = data.fetch_result.offset_next_page;
    if (state.mixinResults.stored_results.results && state.mixinResults.stored_results.results.length > 0) {
      state.mixinResults.stored_results.results.push(...data.fetch_result.results);
    } else{
      state.mixinResults.stored_results = data.fetch_result;
    }
  },
  resetMixinResults(state) {
    state.mixinResults = {
      body: null,
      Status,
      Errors,
      status: Status.NONE,
      valid: true,
      valid_status: Status.NONE,
      count_result: null,
      error: null,
      verbose_body: null,
      stored_results: [],
      offset_next_page: null
    };
  },
  setSpecificKeyResultMixin(state, data){
    state.mixinResults[data.key] = data.value;
  },
  setTimeConstraints(state, data){
    state.timeConstraints = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};